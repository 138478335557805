import { FC, ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import { applicationsRoutes } from './Routes'

export const Router: FC = (): ReactElement => {
  return (
    <section className="porta-content__container">
      <Routes>
        {applicationsRoutes.map((route, i) => {
          return (
            <Route
              path={route.path}
              element={route.element}
              key={i}
              index={route?.index}
            />
          )
        })}
      </Routes>
    </section>
  )
}
