import { RouteProps } from 'react-router-dom'
import { Dashboard } from '../pages'

type BreadcrumbsPropsItem = {
  name?: string
  to?: string
}

enum Permission {
  admin = 'SuperAdmin',
  usersEditor = 'EditorUsers',
  appsEditor = 'EditorSpecificApps',
}

const { admin, appsEditor } = Permission

export interface RoutesProps extends RouteProps {
  name?: string
  crumbs?: BreadcrumbsPropsItem[]
  permissions?: Permission[]
}

export const applicationsRoutes: RoutesProps[] = [
  {
    path: '/porta/gjirafa/home',
    element: <Dashboard />,
    name: 'Home',
    crumbs: [{ name: 'Home' }],
    permissions: [admin],
    index: true,
  },
]
