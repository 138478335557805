import { AuthProvider } from 'oidc-react'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { oidcConfig, userManager } from './config/oidc-auth'
import { PermissionsProvider } from './context'
import { useToggle } from './hooks'
import ProtectedRouter from './router/ProtectedRouter'
import { Router as MainRouter } from './router/Router'
import { setPostLoginUri } from './utils'
// @ts-ignore
import { getUserData } from '@nodus/authentication'

export default function Root(props: any) {
  const { setVisible } = useToggle()
  const { access_token } = getUserData()

  const iFrames = document.getElementsByTagName('iframe')
  if (!!iFrames) {
    for (let index = 0; index < iFrames.length; index++) {
      const element = iFrames[index]
      element.style.display = 'none'
    }
  }
  useEffect(() => {
    setPostLoginUri()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !access_token && setVisible(true)
  }, [access_token]) // eslint-disable-line react-hooks/exhaustive-deps

  userManager.events.addSilentRenewError(() => {
    setVisible(true)
  })

  return (
    <>
      <AuthProvider {...oidcConfig}>
        <PermissionsProvider>
          <ProtectedRouter>
            <Routes>
              <Route path="*" element={<MainRouter />} />
            </Routes>
          </ProtectedRouter>
        </PermissionsProvider>
      </AuthProvider>
    </>
  )
}
