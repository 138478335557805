import { VectorMap } from '@react-jvectormap/core'
import worldMerc from '@react-jvectormap/world/worldMerc.json'
import { FC, useEffect, useRef, useState } from 'react'
import { cx } from '../../utils'
import { IMapOverview } from '../interface'

interface IMap {
  data: IMapOverview[]
}

export const Map: FC<IMap> = ({ data }) => {
  const mapRef = useRef<any>(null)
  const [isShowMap, setIsShowMap] = useState(true)

  const gdpData: any = data?.reduce(
    (acc, current) => ({
      ...acc,
      [current.code]: current.number
    }),
    {}
  )

  useEffect(() => {
    setIsShowMap(false)
    setTimeout(() => {
      setIsShowMap(true)
    })
  }, [data])

  const findCountry = (code: string) => {
    let countryObj = {
      name: '',
      percentageComparison: 0,
      number: 0
    }

    const selectedCountry = data?.find((item) => item.code === code)
    if (!!selectedCountry) countryObj = selectedCountry
    return countryObj
  }

  const showTooltip = (e: any, el: any, code: any) => {
    const { percentageComparison, number } = findCountry(code)

    const noComparison = percentageComparison === 0
    const positiveComparison = percentageComparison > 0
    const comparisonValue = noComparison ? '-' : percentageComparison

    let content = `<div class="map-tooltip">
    <img src="https://flagcdn.com/48x36/${code.toLocaleLowerCase()}.png" alt="..." />
    <div class="map-tooltip__content">
       <h6>${el[0].innerText}</h6>
       <div class="flag__container">
          <p>${number}</p>
          <div class="${cx([
            '',
            noComparison && 'color--orange',
            !noComparison && positiveComparison
              ? 'color--green'
              : 'color--warning'
          ])}">${comparisonValue}%</div>
       </div>
     </div>
   </div>`
    return el.html(content)
  }

  useEffect(() => {
    return () => {
      const boxes = document.querySelectorAll('.jvectormap-tip')
      boxes.forEach((box) => {
        box.remove()
      })
    }
  }, [])

  return (
    <>
      {isShowMap && (
        <VectorMap
          map={worldMerc}
          mapRef={mapRef}
          backgroundColor="transparent"
          style={{
            width: '100%',
            height: '450px'
          }}
          className="map"
          regionStyle={{
            initial: {
              fill: '#E0E5EB',
              stroke: 'none',
              strokeWidth: 0,
              strokeOpacity: 0
            },
            hover: {
              fillOpacity: 0.8,
              cursor: 'pointer'
            },
            selected: {
              fill: '#1D79F2'
            },
            selectedHover: {}
          }}
          regionsSelectable={true}
          series={
            {
              regions: [
                {
                  values: gdpData,
                  scale: ['#A3B0C2', '#052D61', '#667C99'],
                  normalizeFunction: 'linear',
                  data
                }
              ]
            } as any
          }
          onRegionTipShow={showTooltip}
        />
      )}
    </>
  )
}

export default Map
