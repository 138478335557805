import { AxiosRequestConfig } from 'axios'
import { useCallback, useState } from 'react'
import { axios } from '../utils'

type RequestMethodTypes = 'get' | 'post' | 'delete' | 'put'

export interface IRequestState {
  loading: boolean
  errors: string | null
  response: any
  statusCode?: number
}

const useFetch = (method: RequestMethodTypes, loading: boolean = false) => {
  const [requestState, setRequestState] = useState<IRequestState>({
    loading: method === 'get' && loading ? true : false,
    errors: null,
    statusCode: 0,
    response: null
  })

  const apiCall = useCallback(
    async (
      url: string,
      data?: object | string,
      successCallback?: (response: any) => void,
      errorCallback?: (error: any) => void,
      headers?: AxiosRequestConfig
    ) => {
      try {
        // debugger;
        setRequestState((current) =>
          !current.loading ? { ...current, loading: true } : current
        )

        const responseReq = await axios[method](
          `${process.env.REACT_APP_BASE_URL}/api${url}`,
          data,
          headers
        )
        const response = await responseReq?.data
        if (
          Boolean(response) ||
          responseReq?.status === 200 ||
          responseReq?.status === 201 ||
          responseReq?.status === 204
        ) {
          // debugger;
          setRequestState(() => ({
            errors: null,
            response,
            loading: false
          }))

          successCallback && successCallback(response)
        } else {
          setRequestState((current) => ({
            ...current,
            errors: response?.message || 'API Error',
            loading: false,
            statusCode: response?.response?.status || 200
          }))

          errorCallback && errorCallback(response)
        }
      } catch (error: any) {
        setRequestState((current) => ({
          ...current,
          errors: error?.message || 'API Error',
          loading: false,
          statusCode: error?.response?.status
        }))
        errorCallback && errorCallback(error)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  )

  return {
    apiCall,
    ...requestState
  }
}

export { useFetch }
