import { useAuth } from "oidc-react";
import { FC, PropsWithChildren } from "react";
import { Loader } from "../components";

const ProtectedRouter: FC<PropsWithChildren<{}>> = ({ children }) => {
  const auth = useAuth();
  if (auth?.userData) return <>{children && children}</>;
  else return <Loader />;
};
export default ProtectedRouter;
